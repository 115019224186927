<template>
  <div class="case-page">
    <van-checkbox
      v-model="skip"
      :checked-color="$global.checkedColor"
      @change="saveSkipStatus"
      class="skip-anima"
      icon-size="16px"
      >跳过动画</van-checkbox
    >

    <RoundProgress v-if="iscode" :bid="bid" :oid="oid" :takeAction="iscode" />
    <div v-else>
      <van-row class="items">
        <van-col :span="24" :class="{ shake: opening }">
          <div
            v-if="quantity === 5"
            class="item item-has5"
            @click="luckyDraw()"
          >
            <i class="case-box box5-1" />
            <i class="case-box case-pink box5-2" />
            <i class="case-box case-red box5-3" />
            <i class="case-box case-pink box5-4" />
            <i class="case-box case-red box5-5" />
          </div>
          <div v-else class="item" @click="luckyDraw()">
            <i class="case-box case-pink" />
          </div>
        </van-col>
      </van-row>
      <div class="draw-end">
        <!-- <van-button class="gradient blue" @click="luckyDraw()" text="开始" /> -->
        <van-button class="gradient" @click="luckyDraw()" text="开始" />
      </div>
    </div>

    <!--滚动抽奖动画-->
    <Marquee v-if="margueeShow" :items="items" :quantity="quantity" />

    <!--打开箱子-->
    <transition name="fade">
      <Opened
        v-if="openedResultShow"
        :items="items"
        :quantity="quantity"
        :beginner="beginner"
      />
    </transition>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import RoundProgress from "@/components/RoundProgress.vue";
import Marquee from "@/components/Marquee.vue";
import Opened from "@/components/Opened.vue";
export default {
  name: "",
  components: {
    RoundProgress,
    Marquee,
    Opened,
  },
  data() {
    return {
      oid: null,
      bid: null,
      items: null,
      quantity: 1,
      opening: false, //正在打开某个盒子就不能打开其他盒子
      openedResultShow: false,
      margueeShow: false,
      skip: false, //跳过动画
      iscode: false,
    };
  },
  computed: {
    BoxBadge() {
      return this.$store.state.BoxBadge;
    },
  },
  created() {
    //是否跳过动画
    let skip = localStorage.getItem("skip");
    this.skip = skip === "true" ? true : false;
    //获取订单参数
    let params = this.$route.params;
    this.oid = params.oid;
    this.bid = params.bid;
    this.beginner = params.beginner === 1 ? true : false;
    this.quantity = params.quantity;
    //如果是直接进来该页面，或者刷新页面，(试玩的quantity是0，所以要加!==0)
    if (!this.quantity && this.quantity !== 0) {
      this.$router.go(-1);
      return false;
    }
    //判断是否密码盲盒
    this.iscode = params.subtype === 1 ? true : false;
    //如果是试玩
    if (this.quantity === 0) {
      this.items = params.items;
    } else {
      //如果不是试玩
      //进入页面就已经打开盒子了
      this.openbox();
    }
  },
  mounted() {
    //从第N轮的密码盒子里打开
    Bus.$off("openBoxOnRound");
    Bus.$on("openBoxOnRound", () => {
      this.luckyDraw();
    });
    //marquee动画结束后打开盒子
    Bus.$off("openedBoxOnBus");
    Bus.$on("openedBoxOnBus", () => {
      this.openedResultShow = true;
      this.opening = false;
    });
  },
  methods: {
    saveSkipStatus() {
      localStorage.setItem("skip", this.skip);
    },
    openbox() {
      this.$ajax.post("/user/cabinet/open/", { oid: this.oid }).then((res) => {
        this.items = res.data;
        this.$store.commit("BoxBadge", this.BoxBadge + res.data.length);
      });
    },
    //开始抽奖
    luckyDraw() {
      if (this.opening) return false; //欧气福袋正在打开
      this.opening = true;
      this.shakeAnima();
      //提交“完成一次试玩”任务
      if (this.quantity === 0) {
        this.$ajax
          .post("/quest/quest/report/", { progress: 1, event: 2 })
          .then(() => {});
      }
    },
    //盒子闪动动画
    shakeAnima() {
      //跳过动画
      if (this.skip) {
        this.openedResultShow = true;
        this.opening = false;
      } else {
        setTimeout(() => {
          this.margueeShow = true;
          setTimeout(() => {
            Bus.$emit("marqueeRun");
          }, 100);
        }, 500);
      }
    },
    putInBox() {
      this.openedResultShow = false;
      if (this.quantity === 0) {
        //this.$router.push({ name: "Cabinet" });
        this.$router.go(-1);
        console.log("已放入盒柜");
      }
    },
  },
};
</script>
