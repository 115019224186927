<template>
  <div class="draw-anima">
    <div class="draw-zone">
      <div
        class="draw-goods"
        :style="{
          transform: `translateX(-${distance}px)`,
          'transition-duration': `${duration}s`,
        }"
      >
        <div
          v-for="(item, i) in turnGoods"
          :key="i"
          :data-id="item.sortid"
          class="item"
        >
          <div class="pic"><img :src="item.pic" /></div>
          <div class="item-title">{{ item.title }}</div>
          <div class="item-price">¥{{ item.price }}</div>
        </div>
      </div>
    </div>
    <van-overlay :show="true" />
    <div class="opening-tip">正在开盒中，请耐心等待…</div>
    <transition name="fade">
      <div v-if="active" class="frame" />
    </transition>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
export default {
  name: "Marquee",
  props: {
    items: Array, //中奖奖品
    quantity: Number, //0是试玩，1是抽奖，5是5连
  },
  data() {
    return {
      turnGoods: [],
      active: false,
      //动画相关
      duration: 7,
      itemWidth: 160, //每项宽度
      winSerial: 20, //中奖商品序号，5连抽是20，30，40，50，60
      distance: 0,
      moving: false,
    };
  },
  computed: {
    Carefree() {
      return this.$store.state.Carefree;
    },
  },
  created() {
    for (let i = 0; i < 30; i++) {
      this.turnGoods.push(this.getRandomItem());
    }
    //把第1个中奖商品替换到turnGoods里
    this.turnGoods.splice(19, 1, this.items[0]);
    //如果是5抽，turnGoods就应该有70个，上面有30个，再增加40个
    if (this.items.length > 1) {
      //第2个中奖商品
      this.turnGoods.splice(29, 1, this.items[1]);
      for (let i = 0; i < 40; i++) {
        this.turnGoods.push(this.getRandomItem());
      }
      //第3、4、5个中奖商品
      this.turnGoods.splice(39, 1, this.items[2]);
      this.turnGoods.splice(49, 1, this.items[3]);
      this.turnGoods.splice(59, 1, this.items[4]);
    }
  },
  mounted() {
    Bus.$off("marqueeRun");
    Bus.$on("marqueeRun", () => {
      this.luckdraw();
    });
  },
  methods: {
    getRandomItem() {
      //随机返回一个盲盒里的商品
      return this.Carefree[Math.floor(Math.random() * this.Carefree.length)];
    },
    luckdraw() {
      if (this.moving) return false;
      this.moving = true;
      let windowWidth = document.documentElement.clientWidth;
      //设置了滚动栏最大宽度是500px
      windowWidth = windowWidth > 500 ? 500 : windowWidth;
      this.distance =
        this.itemWidth * this.winSerial - windowWidth / 2 - this.itemWidth / 2;
      //每次滚动动画时长
      let rollInterval = 8000;
      //如果是5抽
      if (this.quantity > 1) {
        //每轮滚动结束后显示的燃烧的框
        let frameShowInterval = 7000;
        for (let i = 0; i < 5; i++) {
          setTimeout(() => {
            this.active = true;
            setTimeout(() => {
              this.active = false;
              if (i === 4) Bus.$emit("openedBoxOnBus");
            }, 1000);
          }, frameShowInterval);
          //递增4000是因为5抽从第二次滚动开始动画时长变为3秒，3秒的动画时间+1秒的展示时间，所以是4000ms
          frameShowInterval += 4000;
        }
        for (let i = 0; i < 4; i++) {
          setTimeout(() => {
            if (i === 0) this.duration = 3; //动画间隔变为3秒
            this.distance += this.itemWidth * 10;
            if (i === 3) this.moving = false;
          }, rollInterval);
          rollInterval += 4000;
        }
      } else {
        setTimeout(() => {
          this.moving = false;
          this.active = true;
          setTimeout(() => {
            //动画结束显示结果
            Bus.$emit("openedBoxOnBus");
          }, 1000);
        }, rollInterval);
      }
    },
  },
};
</script>
