<template>
  <div class="openres" :class="`opbg-rarity-${active.rarity}`">
    <van-swipe
      ref="openedSwipe"
      :loop="false"
      @change="onChange"
      class="opened-swipe"
    >
      <van-swipe-item v-for="(item, i) in items" :key="i">
        <div class="opened-item" :class="`item-rarity-${item.rarity}`">
          <div class="rarity">{{ item.rarity | grade("款") }}</div>
          <div class="pic"><img :src="item.pic" /></div>
          <div class="title">{{ item.title }}</div>
        </div>
      </van-swipe-item>
      <template #indicator>
        <div class="indicator">{{ current + 1 }}/{{ items.length }}</div>
      </template>
    </van-swipe>

    <div v-if="items.length > 1" class="swipe-btn">
      <van-icon v-if="current > 0" @click="swipeto('left')" name="arrow-left" />
      <van-icon
        v-if="current + 1 < items.length"
        @click="swipeto('right')"
        name="arrow"
      />
    </div>

    <!--试玩-->
    <div class="draw-end" v-if="quantity === 0">
      <van-button class="gradient" text="赶紧来把真的！" @click="goback()" />
      <div class="tip">试玩结果仅供参考</div>
    </div>
    <!--真抽-->
    <div class="draw-end" v-else>
      <van-button
        class="gradient blue"
        text="不喜欢，闪出"
        @click="toRecycle()"
      />
      <van-button
        class="gradient"
        :text="beginner ? '试试别的' : '再来一发'"
        @click="goback()"
      />
      <router-link class="tip" to="/box/cabinet">
        <i class="to-cabinet-ico" />
        前往盒柜查看
      </router-link>
    </div>

    <!--闪出确认-->
    <van-popup v-model="recyclePop" closeable position="bottom" round>
      <RecycleConfirm
        :selected="[active]"
        :recycleCost="active.recycle_price"
      />
    </van-popup>
    <!--/闪出确认-->
  </div>
</template>

<script>
import RecycleConfirm from "@/components/RecycleConfirm.vue";
export default {
  name: "Opened",
  props: {
    items: Array,
    quantity: Number, //0是试玩，1是抽奖，5是5连
    beginner: Boolean,
  },
  components: { RecycleConfirm },
  data() {
    return {
      recyclePop: false,
      active: null,
      current: 0,
    };
  },
  computed: {},
  created() {
    this.active = this.items[0];
  },
  methods: {
    onChange(i) {
      this.current = i;
      this.active = this.items[i];
    },
    swipeto(dir) {
      let swipe = this.$refs.openedSwipe;
      if (dir === "left") swipe.prev();
      else swipe.next();
    },
    toCabinet(id) {
      this.$parent.putInBox();
    },
    goback() {
      if (this.beginner) {
        this.$router.push({ name: "Box" });
      } else {
        this.$router.go(-1);
      }
    },
    toRecycle() {
      this.recyclePop = true;
    },
    reload() {
      let itemlen = this.items.length;
      for (let i = itemlen - 1; i >= 0; i--) {
        const item = this.items[i];
        if (item.cid === this.active.cid) {
          this.items.splice(i, 1);
          break;
        }
      }
      //商品回收完了以后就回去福袋页
      if (itemlen <= 1) {
        this.goback();
        return false;
      }
      //回收了福袋后，焦点指向现存的第一个商品
      this.active = this.items[0];
      this.current = 0;
    },
  },
};
</script>
